export default {
    data() {
        return {
            validationStatusConst: [
                {label: this.$t('validation_status.validated'), value: 'VALIDATED', color: "success"},
                {label: this.$t('validation_status.redaction'), value: 'REDACTION', color: "info"},
                {label: this.$t('validation_status.archived'), value: 'ARCHIVED', color: "success"},
            ],
        }
    },
    methods: {
        displayForValidationStatusConst(value) {
            let result = this.validationStatusConst.find(el => el.value === value);
            return result? result.label : '';
        },
        getColorForValidationStatusConst(value) {
            let result = this.validationStatusConst.find(el => el.value === value);
            return result? result.color : '';
        }
    }
}